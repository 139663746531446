#Section-cuadros-opciones {
  display: flex;
  justify-content: center;
  background-color: #8888881c;
  align-items: center;
  height: 10.5em;
  gap: 30px;
  box-shadow: 10px 3px 5px 5px rgb(231 231 231);
}

.Container-cuadros-opciones {
  display: flex;
  flex-wrap: wrap;
  width: 20%;
  border-radius: 3%;
  padding: 0px 8px 0 10px;
  border: 0px solid #ccc; /* Agregar borde */
  transition: all 0.3s ease;
}

.Texto-opciones-main {
  margin: 23px 0 0 5px;
}

.Container-icono-opciones {
  margin: 17px 5px 0 0;
}

.Link-cuadros-opciones {
  text-decoration: none;
  padding: 0 0 5px 0;
  color: #d48810;
}

.Icono-opciones {
  width: 2em;
  height: 2em;
}

.Container-cuadros-opciones:hover {
  border: 1px solid #ccc; /* Agregar borde */
  background-color: #ebeaea; /* Opcional: cambiar el fondo al hacer hover */
  transform: scale(
    1.05
  ); /* Opcional: aumentar ligeramente el tamaño al hacer hover */
}

@media screen and (max-width: 768px) {
  #Section-cuadros-opciones {
    flex-direction: column;
    height: 100%;
    gap: 0;
  }
  .Container-cuadros-opciones {
    justify-content: center;
    width: 80%;
    height: 7em;
  }
  .Container-cuadros-opciones p {
    margin: 0;
  }
}
