#Container-main-proyectos {
  position: relative;
  margin: 70px 0 30px 0;
  height: 83em;
}
.SectionProyectos {
  position: relative;
  margin: 0 auto;
  width: 90%;
  height: 370px;
  background-image: url("/public/Imagenes/main_header.jpg"); /* Cambia la ruta según tu imagen */
  background-size: cover;
  background-position: center;
}
#Section-imagenes-main {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}
#ContainerProyectoMain {
  width: 45%;
  height: 30%;
}

#ImgMainProyectos {
  width: 100%;
}

#ImgPrincipalProyecto {
  width: 100%;
  height: 23em;
}
#ListaMainProyecto {
  display: flex;
  justify-content: space-evenly;
  padding: 0;
  margin: 0 auto;
  gap: 10px;
}
#ListaMainProyecto li {
  height: 7em;
  list-style: none;
  width: 32%;
}
#ListaMainProyecto img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

#ListaMainProyecto img.selected {
  opacity: 0.5;
}

/*CSS Para /Proyecto*/

.SectionInicial-proyectos {
  margin: 0 auto;
  width: 90%;
  height: 370px;
  background-image: url("/public/Imagenes/header.jpg"); /* Cambia la ruta según tu imagen */
  background-size: cover;
  background-position: center;
}
#Section-texto-proyectos {
  position: static;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Cambia el último valor para ajustar la opacidad */
}
#Section-texto-proyectos h1 {
  position: relative;
  top: 30%;
  font-size: 5em;
  color: white;
}
#Section-texto-proyectos p {
  position: relative;
  top: 17%;
  color: #acacac;
}
.Lista-Proyectos {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}
#li-img-proyectos {
  position: relative;
  max-width: 44.2%;
  list-style: none;
}

#li-img-proyectos img {
  width: 100%;
  height: 100%;
  display: block;
}

.overlay-proyecto-images {
  position: absolute;
  top: 0;
  pointer-events: none;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Background with some transparency */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease; /* Smooth transition */
}

.overlay-proyecto-images h2 {
  transition: transform 0.3s ease; /* Smooth transition for movement */
  transform: translateY(0); /* Initial position */
}

#li-img-proyectos:hover .overlay-proyecto-images {
  opacity: 1; /* Show the overlay when hovering over the li */
}
#li-img-proyectos:hover .overlay-proyecto-images h2 {
  transform: translateY(-25px);
}

.ActiveimgProyecto {
  margin: 0 auto;
  max-width: 80%; /* Ocupa toda la ventana del navegador */
  height: 80%; /* Ocupa toda la ventana del navegador */
  position: fixed; /* Fija la imagen en la ventana */
  background: #000000b0;
  padding: 10px 180px;
  top: 10%;
  left: 5%;
  right: 5%;
  z-index: 1000;
  object-fit: cover;
}
.ActiveimgProyecto img {
  position: relative;
  top: 60px;
  max-width: 80%;
  height: 80%;
}
.Btn-Anterior-img-proyectos {
  position: absolute;
  top: 40%;
  width: 7em;
  height: 7em;
  left: 13%;
  color: #ffffff;
}
.Btn-Siguiente-img-proyectos {
  position: absolute;
  top: 40%;
  width: 7em;
  height: 7em;
  right: 13%;
  color: #ffffff;
}
.Btn-Close-img-proyectos {
  position: absolute;
  top: 5%;
  width: 3em;
  height: 3em;
  right: 5%;
  color: #ffffff;
}
@media screen and (max-width: 768px) {
  .SectionInicial-proyectos {
    height: 250px;
  }
  #Container-main-proyectos {
    margin-top: 0;
    height: 113em;
  }
  .SectionProyectos {
    height: 270px;
  }

  #li-img-proyectos {
    max-width: 90%;
  }
  #ListaMainProyecto li {
    height: 5.2em;
  }

  #Section-texto-proyectos h1 {
    font-size: 2em;
    top: 30%;
  }
  #Section-texto-proyectos p {
    top: 23%;
  }
  #nombre-proyecto {
    font-size: 23px;
  }

  #Section-imagenes-main {
    flex-direction: column;
  }
  #ContainerProyectoMain {
    width: 100%;
  }
  #ImgPrincipalProyecto {
    height: 15em;
  }
  .Lista-Proyectos {
    gap: 10px;
  }

  .ActiveimgProyecto {
    padding: 0;
    max-width: 100%;
  }
  .ActiveimgProyecto img {
    position: relative;
    top: 110px;
    max-width: 75%;
    height: 50%;
  }
  .Btn-Siguiente-img-proyectos {
    position: absolute;
    top: 40%;
    width: 3em;
    height: 3em;
    right: 0%;
    color: #ffffff;
  }
  .Btn-Anterior-img-proyectos {
    position: absolute;
    top: 40%;
    width: 3em;
    height: 3em;
    left: 0%;
    color: #ffffff;
  }
}
