.Container-links {
  position: fixed;
  z-index: 1000;
  background-color: white;
  width: 100%;
}

#Container-nav {
  margin-top: 30px;
}

.Lista-Opciones {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
  margin: 0 auto;
  box-shadow: 0px -1px 5px 5px rgb(98 102 103);
}

.select-idioma {
  padding: 5px;
  background: #94d4bd;
  border-radius: 5px;
  border: none;
  font-size: 17px;
  color: white;
}

.Lista-Opciones h1 {
  margin-left: 30px;
  color: #ccc;
  font-size: 1.2em;
  font-family: math;
}
.Lista-Opciones p {
  color: #000;
  color: cadetblue;
}

#Second-item-link {
  margin-left: 5em;
}
#Last-item-link {
  flex-grow: 0.3;
}

.Li-opciones {
  list-style: none;
  background-color: white;
}

.Active {
  border-radius: 5px;
  border: 1px solid #ccc;
  color: white;
}

.Link-opciones {
  text-decoration: none;
  color: rgb(98 102 103);
  font-family: sans-serif;
  font-weight: bold;
  padding: 10px;
  transition: 0.5s;
}

.Link-opciones:hover {
  color: #94d4bd;
}

@media screen and (max-width: 768px) {
  .Lista-Opciones {
    display: flex;
    margin: 0 auto;
    position: relative;
    font-size: 9px;
    font-size: 10px;
    gap: 10px;
    padding: 0;
  }
  .select-idioma {
    position: relative;
    margin: 0;
    padding: 2px;
    margin-left: 30px;
  }
  #Second-item-link {
    margin-left: 0em;
  }
  .Link-opciones {
    padding: 3px;
  }
  #Texto-header {
    max-width: 20%;
  }
  .Lista-Opciones h1 {
    max-width: 10%;
    margin: 0;
    padding: 5px;
    position: relative;
    left: 20px;
  }
  .hide-on-mobile {
    display: none;
  }
}
