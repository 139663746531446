@import url("https://fonts.googleapis.com/css?family=Roboto:700");

#P_animation {
  text-shadow: 0 0 7px rgba(255, 255, 255, 0.3),
    0 0 3px rgba(255, 255, 255, 0.3);
  font-size: 65px;
}
.container {
  color: #e5e5e5;
  font-size: 2.05rem;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.animation {
  height: 70px;
  overflow: hidden;
  margin-left: 1rem;
  margin-top: 30px;
}

.animation > div > div {
  height: 2.81rem;
  margin-bottom: 2.81rem;
  display: inline-block;
  border-radius: 10px;
  padding: 3px;
  width: 200px;
}

.animation div:first-child {
  animation: text-animation 8s infinite;
}

.first div {
  background-color: #20a7d8;
}
.second div {
  background-color: #cd921e;
}
.third div {
  background-color: #c10528;
}

@keyframes text-animation {
  0% {
    margin-top: 0;
  }
  10% {
    margin-top: 0;
  }
  20% {
    margin-top: -5.62rem;
  }
  30% {
    margin-top: -5.62rem;
  }
  40% {
    margin-top: -11.24rem;
  }
  60% {
    margin-top: -11.24rem;
  }
  70% {
    margin-top: -5.62rem;
  }
  80% {
    margin-top: -5.62rem;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: 0;
  }
}

/* Media query para dispositivos móviles */
@media screen and (max-width: 768px) {
  #P_animation {
    font-size: 20px;
  }
  .container {
    font-size: 1.5rem;
  }
  .animation > div > div {
    width: 150px;
  }
}
