.Container-contactenos {
  background-image: linear-gradient(0deg, #085d55, white);
  height: 380px;
}

.Container-parrafos h1 {
  color: #085d55;
}
.Container-parrafos p {
  margin: 0 auto;
  position: relative;
  max-width: 50%;
  font-family: sans-serif;
}

.Container-telefonos h4 {
  margin-top: 30px;
  color: #085d55;
}
.Container-telefonos h5 {
  margin: 5px;
  color: white;
}

.Container-btnContacto {
  margin-top: 30px;
}

.Container-btnContacto button {
  padding: 10px 30px;
  border-radius: 17px;
  background-color: #10ff53;
  color: white;
  border: none;
  font-size: medium;
  font-weight: bold;
}

/*PARA CSS DE COTIZAR*/
#Container-cotizar {
  width: 100%;
  height: 40px;
  background-color: orange;
  padding: 12px 0;
  transition: all 0.5s;
}
#Container-cotizar:hover {
  background-color: #085d55;
}
@media screen and (max-width: 768px) {
  .Container-parrafos p {
    max-width: 85%;
  }
  #Container-cotizar h3 {
    font-size: 15px;
    color: white;
  }
}
