.project-container {
  padding: 20px;
  font-family: Arial, sans-serif;
}

.project-header {
  margin: 30px 0 50px 0;
}
.project-header h1 {
  color: #4caf50;
  margin-bottom: 10px;
}

.back-to-projects {
  position: relative;
  left: 38%;
  border: none;
  padding: 7px;
  border-radius: 3px;
  color: white;
  background-color: #333;
  top: -40px;
}

.project-header p {
  color: #333;
  margin: 0 auto;
  width: 50%;
  text-align: center;
}

.project-details {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
  /* height: 400px; */
  gap: 10%;
}

.project-main-image {
  position: relative;
  width: 40%;
  height: 100%;
}

.project-main-image img {
  width: 100%;
  height: 100%;
}

.overlay-ubicacion {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay h2 {
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.details-table {
  width: 35%;
  height: 1005;
  border-collapse: collapse;
  margin-top: 0;
}

.details-table th {
  padding: 10px;
  font-size: 23px;
}
.details-table td {
  padding: 10px;
  border-bottom: 1px solid #ddd; /* Add horizontal lines */
}

.details-table th {
  text-align: left;
  font-weight: bold;

  color: #007bff; /* Updated to blue color */
}

.details-table td {
  text-align: left;
}

.td-detalles-proyecto {
  color: #acacac;
}

.project-gallery {
  margin-top: 5%;
}

.project-gallery h2 {
  color: #4caf50;
  margin-bottom: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
  gap: 2px;
}

.gallery-grid img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  border: 1px solid #ddd;
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .project-details {
    flex-direction: row; /* Stack the table and image on top of each other */
    align-items: center;
  }
  .project-main-image {
    height: 250px;
  }

  .back-to-projects {
    left: 0;
    top: 30px;
  }

  .project-gallery {
    position: relative;
    /* top: 17em; */
  }

  .project-main-image {
    width: 100%; /* Make the image take the full width */
    margin-bottom: 20px;
  }

  .project-header p {
    width: 90%;
  }

  .details-table {
    width: 100%; /* Make the table take the full width */
  }

  .overlay h2 {
    font-size: 20px; /* Adjust the text size for smaller screens */
  }

  .gallery-grid {
    grid-template-columns: repeat(
      auto-fill,
      minmax(150px, 1fr)
    ); /* Adjust the grid layout */
  }
}

@media (max-width: 480px) {
  .overlay h2 {
    font-size: 18px; /* Further adjust the text size for very small screens */
  }

  .gallery-grid {
    grid-template-columns: 1fr; /* Show one image per row */
  }

  .gallery-grid img {
    height: 255px;
  }
}

@media (min-width: 1024px) {
  .project-details {
    height: 350px;
  }
}
