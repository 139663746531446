.header {
  /* Estilos del header */
  position: relative;
  text-align: right; /* Alineación a la derecha */
  color: white;
}

.header-background {
  /* Estilos del fondo */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 550px;
  background-image: url("/public/Imagenes/header4.jpeg"); /* Cambia la ruta según tu imagen */
  background-size: cover;
  background-position: center;
}

.header-background::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.7
  ); /* Cambia el último valor para ajustar la opacidad */
}

.header-content {
  /* Estilos del contenido */
  position: relative;
  z-index: 1; /* Para que esté sobre el fondo */
  margin-left: 45px;
}

.header-titles {
  /* Estilos del contenedor de títulos */
  display: flex;
  flex-direction: column; /* Para que los títulos estén en columna */
  align-items: flex-start; /* Alineación de los títulos a la derecha */
  padding: 10px 0px 0 100px;
}

.title-wrappers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 28em;
  height: 0px;
  margin-left: 0;
}

.title-wrapper {
  /* Contenedor para envolver los títulos */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
}

.Continuacion-letra {
  margin-left: 1.5em;
}
#Letra-inicial-header {
  font-size: 2em;
}
.Texto-header {
  margin-left: 5.9em;
}
.Letra-inicial {
  margin: 35px 0px 0 0px;
  padding: 0;
  position: absolute;
  font-size: 13em;
  color: white;
}

.header-title {
  /* Estilos del título */
  font-size: 100px;
  color: #94d4bd;
  font-weight: 700;
  font-family: fantasy;
  height: 30px;
}

.sub-title {
  /* Estilos del subtítulo */
  font-size: 25px;
  padding-left: 0px;
  color: #ffffff;
}

.header-title2 {
  font-size: 65px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  height: 0px;
}

.sub-title2 {
  /* Estilos del subtítulo */
  font-size: 25px;
  color: #d48810;
  margin-top: 80px; /* Ajuste según sea necesario */
  height: 0;
  padding: 0;
  margin-left: 5px;
}

.Container_btnContacto {
  margin-top: 80px;
}

.btn-contactenos {
  /* Estilos base del botón */
  display: inline-block;
  padding: 20px 30px;
  background-color: #00000080;
  color: rgb(0, 0, 0);
  text-decoration: none;
  border-radius: 5px; /* Esquinas redondeadas */
  transition: background-color 1s; /* Transición suave para el efecto hover */
  font-weight: bold;
  font-size: 17px;
  color: white;
  border: 1px solid;
}

.btn-contactenos:hover {
  /* Estilos al pasar el cursor */
  background-color: #0056b3; /* Cambia el color de fondo en hover */
}
.Main2 {
  margin-top: 158px;
}

#Container-Subtitle-opciones h1 {
  color: #ffffff;
  font-family: sans-serif;
}

.DirijirWhatsApp {
  text-decoration: none;
  color: white;
}

/* Estilos para dispositivos móviles */
@media screen and (max-width: 768px) {
  .header-background {
    height: 350px; /* Reducir la altura del fondo */
  }

  .header-content {
    margin-left: 0; /* Eliminar el margen izquierdo */
    text-align: center; /* Centrar el contenido */
  }

  .header-titles {
    padding: 10px 20px; /* Reducir el padding */
    align-items: center; /* Centrar los títulos */
    text-align: center; /* Centrar los títulos */
  }

  .title-wrapper {
    align-items: center; /* Centrar los títulos */
  }

  .title-wrappers {
    gap: 2em; /* Ajustar el espacio entre elementos */
    padding-top: 45px;
  }

  #Letra-inicial-header {
    position: absolute;
    right: 90px;
  }

  .header-title2,
  .sub-title2 {
    font-size: 23px; /* Reducir el tamaño del texto */
  }

  .header-title {
    font-size: 37px;
  }

  .Continuacion-letra {
    margin: 45px 0 10px 25px;
  }

  .sub-title {
    font-size: 14px;
    margin: 0 0px 59px 10px;
  }

  .sub-title2 {
    margin: 0 0 12px 1px;
  }

  .Container_btnContacto {
    margin-top: 20px; /* Ajustar margen superior */
  }

  .Main2 {
    margin-top: 83px; /* Ajustar margen superior */
  }

  .Main2 h1 {
    font-size: 12px;
  }

  #Container-Subtitle-opciones {
    margin: 0 auto;
    position: relative;
    background: #44766b;
    padding: 10px;
    box-shadow: 5px 2px 3px 3px rgb(84 115 125); /* Ajustar sombra */
  }

  .btn-contactenos {
    padding: 5px;
    font-size: 12px;
    margin-top: 17px;
  }
}
