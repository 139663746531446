/* Offcanvas.css */
.offcanvas {
  position: fixed;
  top: 0;
  right: -250px; /* Hidden by default */
  width: 250px;
  height: 100%;
  background-color: #333;
  color: white;
  transition: right 0.3s ease;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
}

.offcanvas.open {
  right: 0; /* Slide in */
  z-index: 1000;
}

.btn-toggle {
  position: fixed;
  /* top: 20px;
  right: 20px; */
  display: none;
  border-radius: 5px;
  padding: 10px 20px;
  background-color: rgb(59, 59, 59);
  color: white;
  border: none;
  cursor: pointer;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
}

.offcanvas-content {
  padding: 5px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 12%);
  z-index: 999; /* Make sure the overlay is above the content */
}

@media screen and (max-width: 768px) {
  .btn-toggle {
    display: block;
    padding: 5px;
    right: 10%;
    top: 0.5%;
  }
  .list-options-mobile {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 40px;
  }

  .link-options-movile {
    text-decoration: none;
    color: #61dafb;
    font-size: 23px;
    transition: 0.5s;
  }
  .link-options-movile:hover {
    color: #d48810;
  }
}
