#Container-nosotros {
  margin-top: 50px;
}
#Container-nosotros h1 {
  color: #44766b;
}

.Resaltar-texto-nosotros {
  color: #896e04;
  font-weight: 500;
}

#Texto-nosotros {
  margin: 0 auto;
  position: relative;
  max-width: 70%;
}

.Container-cualidades {
  padding: 30px;
}

.Section-cualidades {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 170px;
}

.Listas-cualidades1 {
  margin-left: 30px;
}

.Container-cualidades h1 {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 23px;
  color: #8b3804;
}

.Section-cualidades li {
  list-style: none;
}

.Lista-texto-cualidades {
  max-width: 230px;
}
.Lista-img-cualidades img {
  max-width: 350px;
  border-radius: 5px;
}

.Listas-cualidades2 {
  margin-top: 75px;
  margin-right: 30px;
}
#ContainerNosotrosServicios {
  position: relative;
  top: 3em;
  height: 28em;
}
#ContainerNosotrosServicios h3 {
  font-size: 2.3em;
  color: rgb(98 102 103);
}
#BtnMasProyectos {
  margin: 0 auto;
  max-width: 50%;
  padding: 10px;
  border-radius: 5px;
  border: navajowhite;
  background-color: orange;
  color: white;
  margin-top: 30px;
  position: relative;
  top: 50px;
  text-decoration: none;
}
#SectionNosotrosServicios {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7em;
}
#SectionNosotrosServicios img {
  width: 23em;
  height: auto;
  border-radius: 10px;
}

/* Estilos para dispositivos móviles */
@media screen and (max-width: 768px) {
  #Container-nosotros {
    margin-top: 20px; /* Ajustar margen superior */
  }

  #Texto-nosotros {
    max-width: 100%; /* Ajustar ancho máximo del texto */
    margin: 0 auto;
    padding: 0 15px; /* Añadir padding lateral */
    font-size: 12px;
  }

  .Container-cualidades {
    padding: 20px; /* Ajustar padding */
  }

  .Section-cualidades {
    align-items: center;
    gap: 17px; /* Ajustar espacio entre elementos */
    padding: 0;
  }

  .Listas-cualidades1 p {
    font-size: 12px;
  }
  .Listas-cualidades1 h1 {
    font-size: 19px;
  }
  .Listas-cualidades2 p {
    font-size: 12px;
  }
  .Listas-cualidades2 h1 {
    font-size: 19px;
  }

  .Listas-cualidades1,
  .Listas-cualidades2 {
    margin-left: 0; /* Eliminar margen izquierdo */
    margin-right: 0; /* Eliminar margen derecho */
    text-align: center; /* Centrar contenido */
  }

  .Listas-cualidades2 {
    margin-top: 25px; /* Ajustar margen superior */
  }

  .Lista-img-cualidades img {
    max-width: 100%; /* Ajustar ancho máximo de la imagen */
    height: auto; /* Mantener la proporción de la imagen */
    border-radius: 5px; /* Mantener el borde redondeado */
  }
  #ContainerNosotrosServicios {
    height: 40em;
  }
  #ContainerNosotrosServicios h3 {
    font-size: 1em;
  }
  #SectionNosotrosServicios {
    flex-direction: column;
    gap: 20px;
  }
  #SectionNosotrosServicios img {
    max-width: 80%;
    height: 100%;
    border-radius: 10px;
  }
}
