#Container-contacto-static {
  position: fixed;
  z-index: 1000;
  margin-left: 80px;
  top: 38em;
}

#Section-contacto-static {
  width: 4em;
  height: 4em;
  border-radius: 100%;
  background-color: green;
  cursor: pointer;
}

#Btn-contacto-static {
  width: 2.5em;
  height: 2.5em;
  margin-top: 10px;
  color: white;
}

#Chat-panel {
  position: fixed;
  bottom: 52px;
  left: 13em;
  right: 0;
  width: 300px;
  border-radius: 10%;
  background-color: white;
  box-shadow: 0px 5px 20px 10px rgb(98 102 103);
  display: flex;
  flex-direction: column;
}

.Chat-header {
  position: relative;
  border-radius: 10px 10px 0 0;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 10px;
  background-color: #25d366;
  color: white;
  text-align: center;
}

.Chat-content {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.Chat-footer {
  padding: 10px;
  text-align: center;
}

.Chat-footer button {
  padding: 10px 20px;
  background-color: rgb(98 102 103);
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  #Container-contacto-static {
    top: 85%;
    margin-left: 30px;
  }
  #Section-contacto-static {
    width: 3em;
    height: 3em;
  }
  #Btn-contacto-static {
    width: 2em;
    height: 2em;
    margin-top: 7px;
  }
  #Chat-panel {
    left: 17%;
    font-size: 10px;
    bottom: 170px;
    width: 70%;
  }
}
