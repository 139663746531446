.Lista-sistemas-servicios {
  display: flex;
  max-width: 70%;
  margin: 0 auto;
  position: relative;
  gap: 30px;
}

#Texto-servicios {
  color: #1f7782;
  margin-bottom: 50px;
}

.Lista-sistemas-servicios li {
  list-style: none;
}

#Lista-parrafo-sistemas {
  max-width: 50%;
}
#Lista-parrafo-sistemas p {
  font-size: 1.2em;
}
#Imagen-vidrios-colores {
  width: 50%;
  border-radius: 10px;
}

.Container-principal-servicios {
  margin-top: 50px;
}

.Section-servicios {
  margin-top: 50px;
}

.Btn-Anterior {
  position: absolute;
  top: 25%;
  width: 7em;
  height: 7em;
  left: 0%;
  color: #ffffff;
}

@keyframes colorChange {
  0%,
  100% {
    fill: white;
  }
  50% {
    fill: rgb(59, 59, 59);
  }
}

.Btn-Anterior {
  animation: colorChange 2.5s infinite linear;
}

.Btn-Siguiente {
  position: absolute;
  top: 25%;
  width: 7em;
  height: 7em;
  right: 0%;
  color: #ffffff;
}

.Btn-Siguiente {
  animation: colorChange 2.5s infinite linear;
}
.Lista-servicios {
  display: flex;
  flex-direction: row;
  max-width: 80%;
  flex-wrap: wrap;
  margin: 0 auto;
  position: relative;
  justify-content: space-evenly;
  gap: 40px;
}
.Li-servicios {
  list-style: none;
  height: 450px;
  width: 40%;
}

.Container-servicios {
  margin: 0 auto;
  /* max-width: 270px; */
  height: 100%;
  background: #ffffff;
  padding: 2px;
  box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  border-radius: 3%;
}
.Container-servicios img {
  width: 99%;
  height: 80%;
  border-radius: 3%;
  object-fit: cover; /* Ensures the image covers the container */
}

.ListaPrincipal-tipos-sistemas {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.Lista-tipos-sistemas {
  display: flex;
  gap: 50px;
  flex-direction: row;
  justify-content: center;
}

.Listas-sistema {
  list-style: none;
  width: 39%;
}
.Listas-sistema h1 {
  margin: 0 0 30px 80px;
  font-size: 2em;
  color: #967010;
}

.Li-textoPrincipal-sistema {
  list-style: none;
  font-size: 1.3em;
  font-family: sans-serif;
  color: #1f7782;
  font-weight: 700;
}

.Sistema {
  list-style: disc;
  color: #0b6238;
}

.Lista-tipos-sistemas .Li-textoPrincipal-sistema {
  list-style: none; /* Esto anula el estilo de viñeta */
}

#Imagen-aluminio-colores {
  max-width: 45%;
  border-radius: 10px;
}

.Lista-dibujar-colores-sistemas {
  display: flex;
  gap: 23px;
  align-items: center;
  margin-left: 4.8em;
}

.Lista-colores-sistemas {
  list-style: none;
}
.Lista-colores-sistemas p {
  color: #967010;
  font-size: 1.2em;
}

.Container-color-sistema {
  padding: 10px;
  border-radius: 50%;
}

.color1 {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: brown;
}
.color2 {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #acacac;
}
.color3 {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: black;
}
.color4 {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #0b6238;
}

#texto-final-servicios {
  margin: 0 auto;
  position: relative;
  max-width: 50%;
  top: 30px;
}
#texto-final-servicios h1 {
  color: #af6d3e;
  margin: 40px;
  font-size: 1.5em;
  font-family: sans-serif;
}

@media screen and (max-width: 768px) {
  #Section-sistemas {
    margin-top: 45px;
  }

  .Lista-sistemas-servicios {
    gap: 0;
    align-items: center;
    margin: 0 auto;
    padding: 0;
  }

  #Lista-parrafo-sistemas {
    max-width: 45%;
  }
  #Lista-parrafo-sistemas p {
    font-size: 10px;
    color: #0b6238;
  }

  .ListaPrincipal-tipos-sistemas {
    margin: 0 auto;
    padding: 0;
    flex-direction: column;
    gap: 40px;
  }

  .Lista-tipos-sistemas {
    font-size: 12px;
    gap: 0;
    margin-left: 125px;
    gap: 10px;
  }

  .Container-servicios img {
    width: 99%;
    height: 80%;
  }

  #Container-textoPrincipal-sistemas {
    margin-left: 3em;
  }

  .Listas-sistema {
    width: 43%;
  }

  .Lista-colores-Al {
    width: 100%;
  }

  .Lista-dibujar-colores-sistemas {
    margin-left: 0.1em;
  }

  .color1 {
    max-width: 20px;
    height: 20px;
  }
  .color2 {
    max-width: 20px;
    height: 20px;
  }
  .color3 {
    max-width: 20px;
    height: 20px;
  }
  .color4 {
    max-width: 20px;
    height: 20px;
  }

  .Lista-servicios {
    max-width: 100%;
    padding: 0;
  }

  .Li-servicios {
    font-size: 10px;
    height: 400px;
    width: 90%;
  }

  #texto-final-servicios h1 {
    font-size: 13px;
  }
}
